const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://vakolps9y9.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://199ky74i1b.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.qberisktoesg.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.qberisktoesg.forwoodsafety.com',
    WEBSOCKET: 'wss://bqgustspmh.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;